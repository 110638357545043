@use "../base";
@use "../variables" as *;

body {
    background-color: $color-jabil-darker-blue;
}

.skipLinks {
    display: none;
}

.redirectContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

.redirectContainer__wrapper {
    text-align: center;

    * {
        color: #fff;
    }

    a {
        color: $color-jabil-light-blue;

        &:hover {
            color: $color-jabil-blue;
        }
    }

    img {
        margin: 0 auto;
    }

    p {
        &:not(:first-child) {
            margin-top: 20px;
        }
    }
}
